<template>
  <button
    class="uk-icon-button uk-position-relative uk-margin-small-left"
    data-uk-icon="bag"
    data-uk-toggle="target: #offCanvasCart"
    aria-label="off canvas cart"
    type="button"
  >
    <span v-if="itemCount" class="uk-badge vb-badge--qty uk-background-secondary vb-text-white is-animated">
      {{ itemCount }}
    </span>
    <span v-else class="uk-badge vb-badge--qty" style="background-color: #e5e7e8">{{ itemCount }}</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavCartButton',
  computed: {
    ...mapGetters('basket', ['items']),
    itemCount() {
      return this.items.length
    },
  },
}
</script>
